<template>
    <div class="app-container">
        <div>
            <el-form :inline="true" class="demo-form-inline">
                <el-form-item label="单位:">
                    <el-select v-model="search.institutionId" size="medium"  filterable clearable @change="handleInstitution"
                        placeholder="请选择单位" class="form-line-item"
                               ref="selectInstitution"
                               @visible-change="isShowSelectOptions"
                               style="width: 237px;"
                    >
                        <el-option v-for="(item,index) in institutionList" :key='index' :label="item.institutionName"
                            :value="item.institutionId"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="批次:">
                    <el-select v-model="search.batchId" size='medium' clearable placeholder="请选择批次"
                        class="form-line-item" :disabled='batchShow'
                               ref="selectBatchId"
                               @visible-change="isShowSelectOptions"
                    >
                        <el-option v-for="(item,index) in batchList" :key='item.batchId' :label="item.batchName"
                            :value="item.batchId"></el-option>
                    </el-select>
                </el-form-item>
<!--                <el-form-item label="评审日期:">-->
<!--                    <el-date-picker v-model="search.time" size='medium' type="daterange" range-separator="至"-->
<!--                        start-placeholder="开始日期" end-placeholder="结束日期">-->
<!--                    </el-date-picker>-->
<!--                </el-form-item>-->
<!--                <el-form-item label="打款日期:">-->
<!--                    <el-date-picker v-model="search.rewardTime" size='medium' type="daterange" range-separator="至"-->
<!--                                    start-placeholder="开始日期" end-placeholder="结束日期">-->
<!--                    </el-date-picker>-->
<!--                </el-form-item>-->
              <el-form-item label="题目:">
                <el-input placeholder="请输入论文题目" style="width:350px;" v-model="search.title" size='medium'
                          class="form-line-item">
                </el-input>
              </el-form-item>
              <el-form-item label="支付状态:">
                <el-select v-model="search.isPay" size='medium' clearable placeholder="请选择规则"
                           class="form-line-item" style="width: 182px;">
                  <el-option label="待支付" value="0"></el-option>
                  <el-option label="已支付" value="1"></el-option>
                </el-select>
              </el-form-item>
            </el-form>
            <el-form :inline="true" class="demo-form-inline">
                <el-form-item label="是否申请开票:">
                  <el-select v-model="search.isApply" size='medium' clearable placeholder="请选择规则"
                             class="form-line-item" style="width: 182px;">
                    <el-option label="否" value="0"></el-option>
                    <el-option label="是" value="1"></el-option>
                  </el-select>
                </el-form-item>

                <el-form-item label="开票状态:">
                  <el-select v-model="search.invoiceStatue" size='medium' clearable placeholder="请选择规则"
                             class="form-line-item" style="width: 182px;">
                    <el-option label="未开票" value="0"></el-option>
                    <el-option label="已开票" value="1"></el-option>
                  </el-select>
                </el-form-item>

                <el-form-item label="发布状态:">
                  <el-select v-model="search.isPublish" size='medium' clearable placeholder="请选择规则"
                             class="form-line-item" style="width: 182px;">
                    <el-option label="未发布" value="0"></el-option>
                    <el-option label="已发布" value="1"></el-option>
                  </el-select>
                </el-form-item>

<!--                <el-form-item label="专家:">-->
<!--                    <el-input placeholder="请输入专家姓名" v-model="search.expertName" size='medium' class="form-line-item">-->
<!--                    </el-input>-->
<!--                </el-form-item>-->
                <!-- <el-form-item label="状态:">
          <el-select v-model="search.statue" size='medium' clearable placeholder="请选择费用状态" class="form-line-item">
            <el-option label="已打款" value="1"></el-option>
            <el-option label="未打款" value="0"></el-option>
          </el-select>
        </el-form-item> -->
<!--                <el-form-item label="题目:">-->
<!--                    <el-input placeholder="请输入论文题目" style="width:350px;" v-model="search.title" size='medium'-->
<!--                        class="form-line-item">-->
<!--                    </el-input>-->
<!--                </el-form-item>-->
                <el-form-item style="margin-left:10px;">
                    <el-button type="primary" size="small" icon="el-icon-search" @click="SearchBillHandle">
                        搜索
                    </el-button>
                    <el-button type="primary" size="small" icon="el-icon-refresh" @click="resetHandle">
                        重置
                    </el-button>
                </el-form-item>
            </el-form>
        </div>

        <!-- 表格 -->
        <div>
              <vxe-toolbar perfect style="background-color:#fff;padding:0px 10px;" :refresh="{query: refresh}">
                <template #buttons>
                  <el-button type="success" size="mini" @click="publish">发布</el-button>
                  <el-button type="danger" size="mini" @click="unpublish">取消发布</el-button>

                </template>

                <!--                <template #tools>-->
                <!--                    <el-button type="success" size="mini" @click="uploadCheckResult" style="margin-right:5px">上传审核结果-->
                <!--                    </el-button>-->
                <!--                    -->
                <!--                </template>-->
              </vxe-toolbar>
<!--            <vxe-toolbar perfect style="background-color:#fff;padding:0px 10px;" :refresh="{query: refresh}">-->
<!--                <template #buttons>-->
<!--                    <el-button type="success" size="mini" @click="downloadFinancial">下载财务报表</el-button>-->


<!--                </template>-->
<!--&lt;!&ndash;                <template #tools>&ndash;&gt;-->
<!--&lt;!&ndash;                    <el-button type="success" size="mini" @click="uploadCheckResult" style="margin-right:5px">上传审核结果&ndash;&gt;-->
<!--&lt;!&ndash;                    </el-button>&ndash;&gt;-->
<!--&lt;!&ndash;                    &ndash;&gt;-->
<!--&lt;!&ndash;                </template>&ndash;&gt;-->
<!--            </vxe-toolbar>-->
            <!-- :loading="loading" -->
            <vxe-table :header-cell-style="headerCellStyle" :cell-style='cellStyle' border stripe round
                highlight-hover-row align="center" ref="paperTable" class="mytable-scrollbar unfinancialList" :data="billList" :loading='loading'
                :checkbox-config="{trigger: 'row', highlight: true, range: true}" show-footer
                :print-config="{}">
                <vxe-column type='seq' title="序号" width="5%" :show-overflow="'tooltip'"></vxe-column>
                <vxe-column type='checkbox' title="全选" width="5%" :show-overflow="'tooltip'"></vxe-column>
                <vxe-column field="institutionName" title="单位" width="12%" :show-overflow="'tooltip'"></vxe-column>
<!--                <vxe-column field="batchName" title="批次" width="12%" :show-overflow="'tooltip'"></vxe-column>-->
<!--                <vxe-column field="responsiblePerson" title="负责人" width="8%" :show-overflow="'tooltip'"></vxe-column>-->
                <vxe-column field="paperName" title="论文题目" width="20%" :show-overflow="'tooltip'"></vxe-column>
                <vxe-column field="expertNumber" title="专家意见数" width="6%" :show-overflow="'tooltip'" ></vxe-column>
                <vxe-column field="unitPrice" title="单价" width="5%" :show-overflow="'tooltip'" ></vxe-column>
                <vxe-column field="price" title="总价" width="5%" :show-overflow="'tooltip'" ></vxe-column>
                <vxe-column field="isPay" title="支付状态" width="6%" :show-overflow="'tooltip'" >
                  <template #default="{ row }">
                        <span :style="{
                          color: row.isPay === 1 ? 'green' : 'red'
                        }">
                          {{
                            row.isPay === 1
                                ? '已支付'
                                : '待支付'
                          }}
                        </span>
                  </template>
                </vxe-column>
                <vxe-column field="paymentTimestamp" title="支付时间" width="10%" :show-overflow="'tooltip'"></vxe-column>
                <vxe-column field="invoiceStatus" title="是否申请开票" width="6%" :show-overflow="'tooltip'">
                  <template #default="{ row }">
                    {{
                      row.invoiceStatus === 0 || row.invoiceStatus === 1 ? '是' : '否'
                    }}
                  </template>
                </vxe-column>
              <vxe-column field="invoiceStatus" title="开票状态" width="6%" :show-overflow="'tooltip'">
                <template #default="{ row }">
                  {{
                    row.invoiceStatus === 1  ? '已开票' : '未开票'
                  }}
                </template>
              </vxe-column>
              <vxe-column field="isBack" title="发布状态" width="6%" :show-overflow="'tooltip'">
                <template #default="{ row }">
                  {{
                    row.isBack === 1  ? '已发布' : '未发布'
                  }}
                </template>
              </vxe-column>
              <!-- 申请开票按钮列 -->
              <vxe-column title="开票" min-width="100" align="center">
                <template #default="{ row }">
                  <el-button type="success"  size="mini" @click="applyInvoice(row)">
                    开票
                  </el-button>
                </template>
              </vxe-column>

              <vxe-modal
                  v-model="uploadInvoiceModel"
                  :position="{ top: '0px' }"
                  width="400"
                  @close="closeHandle"
                  :show-footer="true"
                  show-zoom
                  resize
                  :transfer="true"
              >
                <template #title>上传发票</template>

                <el-row :gutter="10">
                  <el-col :span="24">
                    <el-upload
                        class="upload-demo"
                        ref="invoiceUpload"
                        :data="uploadData"
                        :auto-upload="false"
                        action="/api/invoice/upload"
                        :on-success="uploadSuccess"
                        :on-error="uploadError"
                        :on-progress="uploadProgress"
                        drag
                        accept=".pdf"
                    >
                      <i class="el-icon-upload"></i>
                      <div class="el-upload__text">
                        将发票 PDF 拖到此处，或 <em>点击上传</em>
                      </div>
                      <div class="el-upload__tip" slot="tip">只能上传 PDF 文件。</div>
                    </el-upload>
                  </el-col>
                </el-row>

                <template #footer>
                  <el-button type="primary" size="small" @click="cfmUploadInvoiceHandle">确认</el-button>
                </template>
              </vxe-modal>

                <!-- <vxe-column title="状态">
                    <template #default="{ row }">
                        {{row.reviewNum}}/{{row.totalReviewNum}}
                    </template>
                </vxe-column> -->
                <!-- <vxe-column title="操作">
          <template #default="{ row }">
            <el-button size="mini" type="primary" style="margin-bottom:5px;">打款情况</el-button>
            </el-button>
          </template>
        </vxe-column> -->
            </vxe-table>
            <vxe-pager perfect align='right' :current-page.sync="page.currentPage" :page-size.sync="page.pageSize"
                :total="page.total" :page-sizes="[10, 20, 100, 1000, {label: '全量数据', value: page.total}]"
                :layouts="['PrevJump', 'PrevPage', 'Number', 'NextPage', 'NextJump', 'Sizes', 'Total']"
                @page-change="handlePageChange">
                <template #left>
                    <vxe-button size="small" @click="firstPage">首页
                    </vxe-button>
                </template>
            </vxe-pager>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'invoice',
        data() {
            return {
                loading: false,
                payStatue: {
                    1: {
                        text: '已支付',
                        color: 'green'
                    },
                    0: {
                        text: '未支付',
                        color: 'red'
                    }
                },
                AlipayBalance: '10000000',
                WeChatBalance: '1000000',
                batchShow: true,
                institutionList: [],
                batchList: [],
                search: {
                    institutionId: '',
                    // expertName: '',
                    batchId: '',
                    title: '',
                    isPay:'',
                    isApply:'',
                    invoiceStatue:'',
                    isPublish:''
                    // statue: '',
                    // time: ['', ''],
                    // rewardTime:['', ''],
                },
                // 分页
                page: {
                    currentPage: 1,
                    pageSize: 10,
                    pageCount: 40, //自己用
                    pageSizes: [1, 5, 10, 15, 1000],
                    total: 0
                },
                // 账单列表
                billList: [],
                //账单详情对话框
                mergeFooterItems: [{
                    row: 0,
                    col: 0,
                    rowspan: 0,
                    colspan: 0
                }],
                uploadInvoiceModel: false,
                uploadData: {},  // 上传时的额外数据
                uploadTitle: "",  // 上传状态标题
                proStatus: "",  // 上传状态，成功/失败等
                percent: 0,  // 上传进度
                showClose: false,  // 是否显示关闭按钮
                errorUrl: "",  // 错误 URL
                currentPaper: null,  // 当前选中的论文
            }
        },
        created() {
            window.addEventListener('keydown', this.handleKeyPress);
            this.getInstitution();
            this.getPaperList();
        },
        methods: {
            handleKeyPress(event) {
                if (event.keyCode === 13) {
                    this.SearchBillHandle();
                }
            },

            //表格刷新
            refresh() {
                this.getPaperList(this.search, this.page.currentPage, this.page.pageSize);
            },
            //获取送审单位
            //获取送审单位
            getInstitution() {
                let params = {
                    pageIndex: 1,
                    pageSize: 20,
                    isFiled:0
                }
                this.$api.schoolCustom.getSendingInstitution(params)
                    .then(res => {
                        this.institutionList = res.data.data;
                    })
                    .catch(err => {
                        this.$message.warning('接口错误');
                    })
            },
            //获取送审单位的批次
            getBatch(institutionId) {
                let param = new URLSearchParams();
                param.append("institutionId", institutionId);
                // param.append("isFiled",0);
                this.$api.sendingP2p.getBatch(param)
                    .then(res => {
                        console.log(res.data.data);
                        this.batchList = res.data.data;
                    })
                    .catch(err => {
                        this.$message.warning("服务器维护");
                    });
            },
            //机构选择改变
            handleInstitution(val) {
                this.search.batchId = '';
                if (val == '') {
                    this.batchShow = true;
                    return;
                }
                this.batchShow = false;
                this.getBatch(val);
            },
            getPaperList() {
                this.loading = true;
                let param = new URLSearchParams();
                param.append("institutionId", this.search.institutionId);
                param.append("batchId", this.search.batchId);
                param.append("title", this.search.title);
                param.append("isPay", this.search.isPay);
                param.append("isApply", this.search.isApply);
                param.append("invoiceStatue", this.search.invoiceStatue);
                param.append("isPublish", this.search.isPublish);
                param.append("pageIndex", this.page.currentPage);
                param.append("pageSize", this.page.pageSize);
                this.$api.financial.getInvoice(param)
                    .then(res => {
                        console.log(res.data);
                        this.billList = res.data.data;
                        this.page.total = res.data.count;
                        this.loading = false;
                      // 假设每个论文的单价是 200，并计算总价
                      const unitPrice = 200;

                      // 为每个论文项动态添加 unitPrice 和 price 字段
                      this.billList.forEach(paper => {
                        paper.unitPrice = unitPrice;  // 设置单价为 200
                        paper.price = paper.expertNumber * unitPrice;  // 计算总价
                      });
                    }).catch(err => {
                        this.$message.warning("服务器维护！");
                    });
            },
            //搜索打款记录
            SearchBillHandle() {
                this.getPaperList(this.search, this.page.currentPage, this.page.pageSize);
            },
            resetHandle() {
                this.search = {
                    institutionId: '',
                    batchId: '',
                    title: '',
                    isPay:'',
                    isApply:'',
                    invoiceStatue:'',
                    isPublish:''
                }
            },
            exportHandle() {
                this.$refs.paperTable.openExport()
            },
            //打印
            printHandle() {
                this.$refs.paperTable.openPrint()
            },
            //分页操作
            handlePageChange({
                currentPage,
                pageSize
            }) {
                console.log(currentPage, pageSize);
                this.page.currentPage = currentPage;
                this.page.pageSize = pageSize;
                this.getPaperList(this.search, this.page.currentPage, this.page.pageSize);
            },
            //每页多少数据改变
            handleChangePageSize(val) {
                this.page.pageSize = val;
                this.page.currentPage = 1;
                this.getPaperList(this.search, this.page.currentPage, this.page.pageSize);
            },
            firstPage() {
                this.page.currentPage = 1;
                this.getPaperList(this.search, this.page.currentPage, this.page.pageSize);
            },
            //表尾数据
            footerMethod({
                columns,
                data
            }) {
                const sums = [];
                let total = 0;
                data.forEach(item => {
                    // console.log(item.reward);
                    total += parseFloat(item.reward);
                })
                columns.forEach((column, columnIndex) => {
                    if (columnIndex === 0) {
                        sums.push('总计')
                    } else {
                        switch (column.property) {
                            case 'reward':
                                sums.push(total);
                                break
                            default:
                                sums.push('-');
                        }
                    }
                })
                // 返回一个二维数组的表尾合计
                return [sums]
            },
            //头部样式
            headerCellStyle({
                row,
                column,
                rowIndex,
                columnIndex
            }) {
                return "background-color:#e0e0e0f1"
            },
            //设置单元格样式
            cellStyle({
                row,
                rowIndex,
                column,
                columnIndex
            }) {
                //设置状态颜色
                let styles = {};
                if (column.property == 'payStatue') {
                    styles['color'] = this.payStatue[row.payStatue].color;
                }
                return styles;
            },
            dateFormat: function (time) {
                var datetime = new Date(time);
                // datetime.setTime(time);
                var year = datetime.getFullYear();
                var month = datetime.getMonth() + 1 < 10 ? "0" + (datetime.getMonth() + 1) : datetime.getMonth() +
                    1;
                var date = datetime.getDate() < 10 ? "0" + datetime.getDate() : datetime.getDate();
                return year + "-" + month + "-" + date;
            },
            // downloadFinancial(){
            //
            // },
            // 导出财务表
            downloadFinancial() {
                console.log(this.$refs.paperTable)
                let seleted = this.$refs.paperTable.getCheckboxRecords();
                console.log(seleted)
                if (seleted.length <= 0) {
                    this.$message.warning("请选择论文!");
                    return;
                }
                let loading = this.$loading({
                    lock: true,
                    text: '下载中',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                let arr = [];
                seleted.forEach(item => {
                    let id = parseInt(item.reviewId)
                    arr.push(id);
                });
                // let params = new FormData();
                // params.append('reviewIds',arr);
                let params = {
                    reviewIds: arr
                };
                this.$api.financial.downloadFinancial(arr)
                    .then(res => {
                        console.log(res)
                        if (res.data.code == 200) {
                            // 下载第1个
                            this.$api.reviewCheck.getTables({
                                path: res.data.data
                            }).then(res1 => {
                                console.log(res1);
                                const blob = new Blob([res1.data], {
                                    type: 'Application/vnd.ms-excel;charset=utf-8'
                                });
                                let url = window.URL || window.webkitURL;
                                const href = url.createObjectURL(blob);
                                let downloadElement = document.createElement('a');
                                downloadElement.href = href;
                                downloadElement.download = this.getFileName(res.data.data); //下载后文件名
                                document.body.appendChild(downloadElement);
                                downloadElement.click(); //点击下载
                                document.body.removeChild(downloadElement); //下载完成移除元素
                                window.URL.revokeObjectURL(href); //释放掉blob对象
                                loading.close();
                            });
                            loading.close();
                            // let path = res.data.data;
                            // this.getZip(path);
                        }
                    }).catch(err => {
                        console.log(err)
                        this.$message.warning("服务器维护!");
                        loading.close();
                    })
            },
            getFileName(str) {
                return str.substr(str.indexOf('\\') + 1)
            },
            downloadExcel(path){


                this.$api.reviewCheck.getStatisticsTable(reviewIds)
                    .then(res => {
                        this.$api.reviewCheck.getTables({
                            path: res.data.data
                        }).then(res1 => {
                            console.log(res1);
                            const blob = new Blob([res1.data], {
                                type: 'Application/vnd.ms-excel;charset=utf-8'
                            });
                            let url = window.URL || window.webkitURL;
                            const href = url.createObjectURL(blob);
                            let downloadElement = document.createElement('a');
                            downloadElement.href = href;
                            downloadElement.download = this.getFileName(res.data.data); //下载后文件名
                            document.body.appendChild(downloadElement);
                            downloadElement.click(); //点击下载
                            document.body.removeChild(downloadElement); //下载完成移除元素
                            window.URL.revokeObjectURL(href); //释放掉blob对象
                            loading.close();
                        })

                    })
                    .catch(err => {
                        this.$message.warning("导出评审信息表错误");
                    })
            },
            isShowSelectOptions(isShowSelectOptions){
                if(!isShowSelectOptions) this.$refs.selectInstitution.blur();
                if(!isShowSelectOptions) this.$refs.selectBatchId.blur();
            },
            applyInvoice(row) {
              console.log("申请开票：", row);
              this.currentPaper = row;  // 保存当前论文信息
              this.uploadInvoiceModel = true;
            },
            closeHandle() {
              this.uploadInvoiceModel = false;
            },
            // 上传成功处理
            uploadSuccess(res, file, fileList) {
              console.log("上传成功返回：", res);

              if (res.code == "200") {
                // 上传成功
                this.$message.success("上传成功");
                this.uploadTitle = "上传成功";
                this.proStatus = "success";
              } else if (res.code == "406") {
                // 上传失败（具体失败情况）
                this.showClose = true;
                this.uploadTitle = "上传失败";
                this.errorUrl = res.data;  // 获取失败信息或错误 URL
                this.proStatus = "exception";
                this.percent = 99;  // 显示上传失败状态

              } else {
                // 其他错误
                this.uploadTitle = "上传失败";
                this.proStatus = "exception";
                this.percent = 99;
                this.$message.warning(res.msg);  // 显示错误消息
              }

              // 清除文件列表
              this.$refs.invoiceUpload.clearFiles();  // 清理 PDF 文件
            },

            // 上传失败处理
            uploadError(err, file, fileList) {
              console.log("上传失败：", err);
              this.$message.warning("服务器维护！");  // 提示服务器维护
              this.showClose = true;
              this.percent = 99;  // 显示上传失败状态
              this.uploadTitle = "上传失败";
              this.proStatus = "exception";
              // 清除文件列表
              this.$refs.invoiceUpload.clearFiles();  // 清理 PDF 文件
            },

            // 上传进度处理
            uploadProgress(event, file, fileList) {
              this.percent = parseInt(event.percent);  // 获取并显示上传进度
            },

            // 确认上传按钮事件
            cfmUploadInvoiceHandle() {
              let loading = this.$loading({
                lock: true,
                text: '正在上传',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
              });


              const files = this.$refs.invoiceUpload.uploadFiles;  // 获取上传的文件
              const paperId = this.currentPaper.paperId;  // 获取论文 ID

              if (files.length === 0) {
                this.$message.warning("请先选择一个文件进行上传");
                loading.close();  // 关闭加载状态
                return;  // 如果没有选择文件，直接返回，不再执行上传
              }

              // 创建 FormData 来提交文件和论文 ID
              let params = new FormData();
              params.append('file', files[0].raw);  // 将选中的 PDF 文件添加到 FormData
              params.append('paperId', paperId);  // 将论文 ID 添加到 FormData

              this.$api.financial.UploadInvoice(params)
                  .then(res => {
                    // 上传成功后的处理
                    if (res.data.code === 200) {
                      this.$message.success("发票上传成功！");
                      this.uploadInvoiceModel = false;  // 关闭上传对话框
                      this.$refs.invoiceUpload.clearFiles();  // 清空已上传的文件
                      this.getPaperList(this.search, this.page.currentPage, this.page.pageSize);
                      loading.close();
                    }else if (res.data.code == 201) {
                      this.$message.warning("警告！");
                      this.uploadInvoiceModel = false;
                      this.$refs.invoiceUpload.clearFiles();  // 清空已上传的文件
                      loading.close();
                    } else {
                          this.$message.warning(res.data.msg);
                          loading.close();
                    }
                  }).catch(err => {
                         this.$message.warning("上传失败");
                          loading.close();
                  })

            },

            publish() {
              console.log(this.$refs.paperTable)
              let selectRecords = this.$refs.paperTable.getCheckboxRecords();
              console.log(selectRecords)
              let backTableDetails = [];
              selectRecords.forEach((item) => {
                if(item.invoiceStatus == 1)
                  backTableDetails.push({
                    paperId:item.paperId,
                    isBack:1
                  });
              });
              if (backTableDetails.length <= 0) {
                this.$message.warning("请选择已开票论文!");
                return;
              }
              let loading = this.$loading({
                lock: true,
                text: '下载中',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
              });
              let params = new FormData();
              params.append('backTableDetails',JSON.stringify(backTableDetails))
              console.log(params);
              this.$api.financial.postUploadBackTable_2(params)
                  .then(res=>{
                    this.$message.success("发布成功");
                    this.getPaperList(this.search, this.page.currentPage, this.page.pageSize);
                    loading.close();
                  })
                  .catch();
            },
            unpublish() {
              console.log(this.$refs.paperTable)
              let selectRecords = this.$refs.paperTable.getCheckboxRecords();
              console.log(selectRecords)
              let backTableDetails = [];
              selectRecords.forEach((item) => {
                if(item.invoiceStatus == 1)
                backTableDetails.push({
                  paperId:item.paperId,
                  isBack:0
                });
              });
              if (backTableDetails.length <= 0) {
                this.$message.warning("请选择已开票论文!");
                return;
              }
              let loading = this.$loading({
                lock: true,
                text: '下载中',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
              });
              let params = new FormData();
              params.append('backTableDetails',JSON.stringify(backTableDetails))
              console.log(params);
              this.$api.financial.unPostUploadBackTable(params)
                  .then(res=>{
                    this.$message.success("取消发布成功");
                    this.getPaperList(this.search, this.page.currentPage, this.page.pageSize);
                    loading.close();
                  })
                  .catch();
            },

        },
        components: {

        }
    }
</script>

<style scoped>
    .form-line-item {
        width: 15em;
    }
    .el-form-item {
        margin-bottom: 10px !important;
    }
</style>

<style >
    .unfinancialList .vxe-table--body-wrapper {
        height: calc(100vh - 390px);
    }
</style>